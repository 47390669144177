import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import { Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import Icon from "../components/elements/Icon";
import AnchorPrismic from "../components/elements/AnchorPrismic";
import CtaSupport from "../components/common/CtaSupport";
import { constructActiveDoc } from "../helpers";
import { imageObject } from "../components/meta/seoHelpers";
import { linkResolver } from "../utils/prismic-linkResolver";
import { siteUrl } from "../../config/site-config";

import "../styles/components/slices/icons-with-text.scss";
import "../styles/pages/steps.scss";

const Steps = ({ data }) => {
  if (!data) return null;

  const { settings, page } = data;
  const {
    title,
    description,
    image,
    image_mobile: imageMobile,
    notice,
    steps,
  } = page.data;

  const stepsList = steps.map((step, index) => (
    <div
      className="icons-text__item"
      id={`step-${index + 1}`}
      data-group={index % 2 === 0 ? "even" : "odd"}
      key={step.content.text}
    >
      <div className="icons-text__icon" data-step={index + 1}>
        <Icon icon={step.icon} />
      </div>
      <div className="icons-text__content">
        <RichText
          render={step.content.richText}
          serializeHyperlink={AnchorPrismic}
        />
      </div>
    </div>
  ));

  return (
    <>
      <Seo page={page} settings={settings} />
      <Helmet>
        <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "HowTo",
          "name": "${title}",
          "image": ${imageObject(image)},
          "step": [${steps.map((step, index) => `{
            "@type": "HowToStep",
            "name": "${`${page.lang === "el-gr" ? "Βήμα" : "Step"} ${index + 1}`}",
            "text": "${step.content.text.replace(/"/g, "&quot;")}",
            "image": ${imageObject(step.icon)},
            "url": "${new URL(`${linkResolver(page)}#step-${index + 1}`, siteUrl)}"
          }`)}]
        }`}</script>
      </Helmet>
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <article className="steps-page">
          <HeaderBackground
            title={title}
            description={description}
            image={image}
            imageMobile={imageMobile}
            headerSize="2"
          />
          <Container className="mw-1920" fluid>
            <Row>
              <Col
                xxl={{ span: 8, offset: 2 }}
                className="col-xxxl-6 offset-xxxl-3"
              >
                <div className="steps-page__list my-8 px-xl-5">{stepsList}</div>
                <div className="mw-600 mx-auto text-center">
                  {notice.text !== "" && (
                    <RichText
                      render={notice.richText}
                      serializeHyperlink={AnchorPrismic}
                    />
                  )}
                  <CtaSupport settings={settings} showText />
                </div>
              </Col>
            </Row>
          </Container>
        </article>
      </Layout>
    </>
  );
};

export const query = graphql`
  query stepsQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicSteps(lang: { eq: $lang }) {
      ...prismicStepsFragment
    }
  }
`;

export default withPrismicPreview(Steps);

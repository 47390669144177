import React from "react";
import { RichText } from "prismic-reactjs";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";
import { SupportPerson } from "../../helpers/Icons";

const CtaSupport = ({ className, settings, showText }) => {
  const {
    support_link: link,
    support_link_label: label,
    support_text: text,
  } = settings.data;

  return (
    // eslint-disable-next-line prettier/prettier
    <div className={`cta-container cta-container--support text-center ${className || ""}`}>
      {text.text !== "" && showText && (
        <div className="cta-text cta-text--support">
          <RichText render={text.richText} serializeHyperlink={AnchorPrismic} />
        </div>
      )}
      <Anchor
        href={link.url}
        target={link.target}
        title={label}
        className="cta-link cta-link--support link-unstyled h2"
      >
        <SupportPerson />
        <span>{label}</span>
      </Anchor>
    </div>
  );
};

export default CtaSupport;
